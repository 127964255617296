import { BrowserRouter as Router, Route,Switch,Link } from "react-router-dom";
const Footer=()=>{
    return(
        
            <section id="footer">  
                <div className="container">  
                <div className="row text-center text-xs-center text-sm-left text-md-left">  
                <div className="col-xs-12 col-sm-4 col-md-4">  
                    <Link to="/"> <img src={process.env.PUBLIC_URL + '/images/logo.png'} alt="product1" className="footer_logo"/> </Link> 
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                     </p>  
                </div>  
                    <div className="col-xs-12 col-sm-4 col-md-4">  
                    <h5> Menu </h5>  
                    <ul className="list-unstyled quick-links">  
                        <li> <Link to="/"> <i className="fa fa-angle-double-right"> </i> Home </Link> </li>  
                        <li> <Link to="/about"> <i className="fa fa-angle-double-right"> </i> About </Link> </li>  
                        <li> <Link to="/shop"> <i className="fa fa-angle-double-right"> </i> Shop </Link> </li>  
                        <li> <Link to="/faq"> <i className="fa fa-angle-double-right"> </i> FAQ </Link> </li>  
                        <li> <Link to="/contact"> <i className="fa fa-angle-double-right"> </i> Contact </Link> </li>  
                        <li> <Link to="/Privacy-policy"> <i className="fa fa-angle-double-right"> </i> Privacy Policy </Link> </li>  
                        <li> <Link to="/Privacy-policy"> <i className="fa fa-angle-double-right"> </i> Terms & Condition </Link> </li>  
                    </ul> 
                    </div>  
                    <div className="col-xs-12 col-sm-4 col-md-4">  
                    <h5> NewsLetter </h5>  
                        <ul className="list-unstyled quick-links">  
                            <input type="text" placeholder="Enter your Email"/>
                            <input type="button" value="Submit"/>  
                        </ul>  
                    </div>  
                    </div>  
                    <div className="row">  
                    <div className="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-5">  
                        <ul className="list-unstyled list-inline social text-center">  
                            <li className="list-inline-item"> <Link to="#"> <i className="fa fa-facebook"> </i> </Link> </li>  
                            <li className="list-inline-item"> <Link to="#"> <i className="fa fa-twitter"> </i> </Link> </li>  
                            <li className="list-inline-item"> <Link to="#"> <i className="fa fa-instagram"> </i> </Link> </li>  
                            <li className="list-inline-item"> <Link to="#"> <i className="fa fa-google-plus"> </i > </Link> </li>  
                            <li className="list-inline-item"> <Link to="#"> <i className="fa fa-envelope"> </i> </Link> </li>  
                        </ul>  
                    </div>  
                    <hr/>  
                    </div>      
                    <div className="row">  
                    <div className="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-2 text-center">  
                        <p className="h6"> 2021 ? All right Reversed.
                        <Link className="text-green ml-2" to="#" target="_blank">   Adorta </Link> </p>  
                    </div>  
                    <hr/>  
                    </div>      
                    </div>  
            </section>  
     
    )
}
export default Footer;