import React,{useState,useEffect} from "react";
import axios from "axios";
import { Link  } from 'react-router-dom';
const Shop =()=>{
    const [data,SetData]=useState([]);
    
    useEffect(()=>{
        axios.get('https://fakestoreapi.com/products')
        .then(function (response) {
            // handle success
        // console.log(response.data); 
            SetData(...data,response.data)
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        })
        .then(function () {
            // always executed
        });
    },[]);
    
    if(data.length>0){
            return(
                <div className="product_list">
                    <div className="container">
                            <h1>Products</h1>
                        <div className="row">
                            {
                            
                                data.map((newdata,i)=>{
                                    return(
                                    <div key={i} className="col-sm-3">
                                        <div className="image_box">
                                            <img src={newdata.image}/>
                                            <Link to={"/products/"+newdata.id}>{newdata.title}</Link>
                                            <span className="price">{newdata.price} Rs.</span><br/>
                                            <span className="rating">{newdata.rating.rate}</span>
                                        </div>
                                    
                                    </div>
                                    )
                                })
        
                            }
                        </div>
                    </div>
                </div>
            )
    }
}
export default Shop;