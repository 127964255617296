import { useParams,useLocation,useHistory} from "react-router-dom";
import React,{useState,useEffect} from "react";
import axios from "axios";
import { Link  } from 'react-router-dom';
const ProductDetail=()=>{
    const {id} = useParams();
    const [data,SetData]=useState([]);
    useEffect(()=>{
        axios.get(`https://fakestoreapi.com/products/${id}`)
        .then(function (response) {
            // handle success
            //console.log(data);
            SetData([...data,'']);
            SetData([...data,response.data]);
        })
        .catch(function (error) {
            // handle error 
            console.log(error); 
        })
        .then(function () {
            // always executed
            
			// Add active class to target link
			const allelement =document.querySelector('#navbarSupportedContent ul li');
            allelement.classList.remove("active");
            const element =document.querySelector('#navbarSupportedContent ul li:nth-child(4)');
            element.classList.add("active");
            test();
		//	document.querySelector('#navbarSupportedContent ul li:nth-child(4)').addClass("active");
        });
    },[]);
    const TotalStart=5;
    var rows = [];
    for (var i = 0; i < TotalStart; i++) {
        if(data.length > 0) {

            const roundrating=Math.round(data[0].rating.rate);
            (roundrating > i ) ? 
            rows.push(<span className="fa fa-star"></span>) :
            rows.push(<span className="fa fa-star-o"></span>) 
        }
            

    }
    
    return(
        <div className="product_list">
            <div className="container">
           
                <div className="row">
                    {
                        (data.length > 0) ?
                            <>
                            
                            <div className="col-sm-6">
                                <img src={data[0].image} alt={data[0].title}/>
                            </div>
                            <div className="col-sm-6">
                                <h1> {data[0].title}</h1>
                                Price: {data[0].price} ₹<br/>
                                 <Link to={"/category/"+data[0].category}><span>{data[0].category}</span></Link>
                                 <br/>
                                 
                                Rating: &nbsp;
                               {
                                rows.map((starow,i)=>{
                                   return(
                                    <span key={i}>
                                     {starow}
                                    </span>
                                    
                                   )
                                })
                                } {data[0].rating.count}<br/>
                                Description: {data[0].description}
                            </div>
                            </>
                      :
                      " "
                    }
                </div>
            </div>
        </div>
    )
}

export default ProductDetail;