const Testimonial=()=>{
    return(
        <div id="testimonialControls" className="carousel slide" data-ride="carousel">
             <h2>Testimonial</h2>
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img className="d-block w-100" src={process.env.PUBLIC_URL + '/images/gallery-lg-7.jpg'} alt="First slide"/>
                    <span>Testimonial1</span>
                    <p>Testimonial related content goes here</p>
                </div>
                <div className="carousel-item">
                    <img className="d-block w-100" src={process.env.PUBLIC_URL + '/images/gallery-lg-8.jpg'} alt="Second slide"/>
                    <span>Testimonial1</span>
                    <p>Testimonial related content goes here</p>
                </div>
                <div className="carousel-item">
                    <img className="d-block w-100" src={process.env.PUBLIC_URL + '/images/gallery-lg-9.jpg'} alt="Third slide"/>
                    <span>Testimonial1</span>
                    <p>Testimonial related content goes here</p>
                </div>
            </div>
            <a className="carousel-control-prev" href="#testimonialControls" role="button" data-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" href="#testimonialControls" role="button" data-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
            </a>
        </div>
    )
}
export default Testimonial;