import './Home.css';
import HomeBanner from "./HomeBanner";
import Testimonial from "./Testimonial";
import React, {useState} from 'react';
import { Link, Route, Switch  } from 'react-router-dom';
const Home=()=>{
    //const [categories,Setcategories]=useState([]);
    // fetch('https://fakestoreapi.com/products/categories')
    //         .then(res=>res.json())
    //         .then(
    //             (result)=>{
    //                     console.log(result);
    //                   //  const rcd=[...]
    //                     Setcategories([...categories,result])   
    //                 }
    //             );
   
    return(
        <> 
            <div className="container-fluid">
                <div className="row">
                    <HomeBanner/>
                </div>
            </div>
            <div className="spedification">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-3">
                            <div className="home-info-block home-info-block-8">
                                <div className="home-sales-icon">
                                    <img src="https://store.in.panasonic.com/pub/media/new-homepage/sales-icon-11c.png" alt="Assured Quality"/>
                                </div>
                                <div className="home-info-cont">
                                    <span className="info-details">Assured Quality</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className="home-info-block home-info-block-8">
                                <div className="home-sales-icon">
                                    <img src="https://store.in.panasonic.com/pub/media/new-homepage/sales-icon-1.png" alt="Assured Quality"/>
                                </div>
                                <div className="home-info-cont">
                                    <span className="info-details">Free Delivery in 48 Hours*</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className="home-info-block home-info-block-8">
                                <div className="home-sales-icon">
                                    <img src="https://store.in.panasonic.com/pub/media/new-homepage/sales-icon-2.png" alt="Assured Quality"/>
                                </div>
                                <div className="home-info-cont">
                                    <span className="info-details">Assured Quality</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className="home-info-block home-info-block-8">
                                <div className="home-sales-icon">
                                    <img src="https://store.in.panasonic.com/pub/media/new-homepage/sales-icon-7.png" alt="Assured Quality"/>
                                </div>
                                <div className="home-info-cont">
                                    <span className="info-details">Priority Installation within 24 Hours*</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="featured_products">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6">
                            
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="image_box">
                                            <img src={process.env.PUBLIC_URL + '/images/jwellery.jpeg'} alt="product1"/>
                                            <div className="product_content">
                                                <Link to="/category/jewelery"><span>Jewelery</span></Link>
                                                {/* <p>Product Description Product Description Product Description</p> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="image_box">
                                            <img src="https://fakestoreapi.com/img/81QpkIctqPL._AC_SX679_.jpg" alt="product1"/>
                                            <div className="product_content">
                                                <Link to="/category/electronics"><span>Electronics</span></Link>
                                                {/* <p>Product Description Product Description Product Description</p> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="image_box">
                                            <img src="https://fakestoreapi.com/img/71YXzeOuslL._AC_UY879_.jpg" alt="product1"/>
                                            <div className="product_content">
                                                <Link to="/category/men's clothing"><span>Men's Clothing</span></Link>
                                                {/* <p>Product Description Product Description Product Description</p> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="image_box">
                                            <img src="https://fakestoreapi.com/img/61pHAEJ4NML._AC_UX679_.jpg" alt="product1"/>
                                            <div className="product_content">
                                                <Link to="/category/women's clothing"><span>Women's Clothing</span></Link>
                                                {/* <p>Product Description Product Description Product Description</p> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                           
                        </div>
                        <div className="col-sm-6">
                           <Testimonial/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="offer"></div>
            <div className="contact_form">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6">
                            <img src={process.env.PUBLIC_URL + '/images/gallery-lg-9.jpg'}/>
                        </div>
                        <div className="col-sm-6">
                            <h3>Contact Us</h3>
                            <form>
                                <input type="text" placeholder="Enter your Name"/>
                                <input type="email" placeholder="Enter your Email"/>
                                <input type="tel" placeholder="Enter your Phone no"/>
                                <textarea placeholder="Enter your requirment"></textarea>
                                <input type="submit" value="Submit"/>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}
export default Home;