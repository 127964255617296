import Nav from './Nav';
import Footer from './Footer';
import { BrowserRouter as Router, Route,Switch,Link } from "react-router-dom";
function App() {
  return (
    <Router>
      <div className="App">
        <Nav/>
        <Footer/>
      </div>

    </Router>
  );
}

export default App;
