import { useParams,useLocation,useHistory} from "react-router-dom";
import React,{useState,useEffect} from "react";
import axios from "axios";
import { Link  } from 'react-router-dom';
const Category=()=>{
    const {nn} = useParams();

    const [data,SetData]=useState([]);
    useEffect(()=>{
        axios.get(`https://fakestoreapi.com/products/category/${nn}`)
        .then(function (response) {
            // handle success
        // console.log(response.data);
            SetData(...data,response.data)
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        })
        .then(function () {
            // always executed
            const allelement =document.querySelector('#navbarSupportedContent ul li');
            allelement.classList.remove("active");
            const element =document.querySelector('#navbarSupportedContent ul li:nth-child(4)');
            element.classList.add("active");
            test();
        });
    },[]);
    
    return(
        <div className="product_list">
            <div className="container">
                    <h1> {nn}</h1>
                <div className="row">
                    {
                        data.map((newdata,i)=>{
                           // const TotalStart=5;
                                // var rows = [];
                                // for (var j = 0; j < TotalStart; j++) {
                                //     if(newdata.length > 0) {

                                //         const roundrating=Math.round(newdata.rating.rate);
                                //         (roundrating > j ) ? 
                                //         rows.push(<span className="fa fa-star"></span>) :
                                //         rows.push(<span className="fa fa-star-o"></span>) 
                                //     }
                                        

                                // }
                            return(
                            <div key={i} className="col-sm-3">
                                <div className="image_box">
                                    <img src={newdata.image}/>
                                    <Link to={"/products/"+newdata.id}>{newdata.title}</Link>
                                    <span className="price">Price: {newdata.price} Rs.</span><br/>
                                    {/* <span className="rating">{newdata.rating.rate}</span> */}
                                </div>
                            
                            </div>
                            )
                        })

                    }
                </div>
            </div>
        </div>
    )
}
export default Category;