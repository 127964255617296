import Shop from './Shop';
import About from './About';
import Home from './Home';
import Category from './Category';
import ProductDetail from './ProductDetail';
import  './App.css';
import { BrowserRouter as Router, Route,Switch,Link } from "react-router-dom";
const Nav=()=>{
    
    return(
        <>
            <nav className="navbar navbar-expand-custom navbar-mainbg">
            <Link className="navbar-brand navbar-logo" to="/">Store</Link>
            <button className="navbar-toggler" type="button" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <i className="fas fa-bars text-white"></i>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ml-auto">
                    <div className="hori-selector">
                        <div className="left"></div>
                        <div className="right"></div>
                    </div>
                    <li className="nav-item active">
                        <Link className="nav-link" to="/"><i className="fas fa-tachometer-alt"></i>Home</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/about"><i className="far fa-clone"></i>About</Link>
                    </li>
                    <li className="nav-item"  >
                        <Link className="nav-link" to="/shop"><i className="fas fa-house-user"></i>Shop</Link>
                    </li>
                    <div className="clear"></div>
                    {/* <li className="nav-item">
                        <Link className="nav-link" ><i className="far fa-calendar-alt"></i>Calendar</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" ><i className="far fa-chart-bar"></i>Charts</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" ><i className="far fa-copy"></i>Documents</Link>
                    </li> */}
                </ul>
            </div>
            </nav>
            <Switch>
                <Route path="/" exact component={Home} />
                    
                <Route path="/about" exact>
                    <About />
                </Route>
                <Route path="/shop" exact>
                    <Shop />
                </Route>
                <Route path="/category/:nn" component={Category}/>
                <Route path="/products/:id" component={ProductDetail}/>
            </Switch>
    </>
    );

}
export default Nav;